import React from 'react'

import Layout from '../layout'
import Section from '../components/Section'
// import Hero from '../components/Sections/HeroSection'
import PageHeader from '../components/Sections/PageHeader'

export default () => (
  <Layout>
    <PageHeader dark={false} />
    <Section>
      <h1>404: NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist.</p>
    </Section>
  </Layout>
)
