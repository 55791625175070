import React from 'react'
import { parameterize } from './utils'
import { ErrorBoundary } from 'react-error-boundary'
import classNames from 'classnames'

const FallbackComponent = ({ componentStack, error }) => (
  <div>
    <p>
      <strong>Oops! An error occured!</strong>
    </p>
    <p>
      <strong>Error:</strong> {error.toString()}
    </p>
    <p>
      <strong>Stacktrace:</strong> {componentStack}
    </p>
  </div>
)

export default ({ background, children, classes, id, affix, useContainer = true, style }) => (
  <section
    id={parameterize(affix) || id}
    className={classNames('page-section', classes, background && 'page-section__background')}
    style={Object.assign(style || {}, { backgroundImage: background && `url(${background})` })}
  >
    <div className={classNames(useContainer && 'container')}>
      <ErrorBoundary FallbackComponent={FallbackComponent}>{children}</ErrorBoundary>
    </div>
  </section>
)
